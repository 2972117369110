<template>
<component :is="component" v-bind="$attrs" v-on="$listeners" />
</template>
<script>
export default {
  name: 'AliothSelect',
  props: {
    multiple: {
      type: Boolean,
    },
  },
  computed: {
    component() {
      return () => import(`./${this.multiple ? 'Multi' : 'Single'}`)
    },
  },
}
</script>
<style lang="scss">
@import './styles';
</style>
